<template>
  <page-view ref="pageView" @reaty="fetchData">
    <template #header>
      <van-nav-bar title="户号管理" left-arrow @click-left="onClickLeft">
        <template #right>
          <van-icon name="plus" size="18" @click="onClickRight"/>
        </template>
      </van-nav-bar>
    </template>

    <loading-layout ref="loadingLayout">
      <template #empty>
        <yhbh-empty fromPage="yhbh" />
      </template>
      <div class="yhbh-list">
        <van-cell-group class="yhbh-item" @click="onItemClick(item)" v-for="(item,index) in list" :key="index">
          <van-cell title="户名" :value="item.yhmc" />
          <van-cell title="户号" :value="item.yhbh"/>
          <van-cell title="地址" :value="item.yhdz"/>
        </van-cell-group>
      </div>
    </loading-layout>

    <van-action-sheet v-model="actionSheetShow" :actions="actions" @select="onActionSelect" cancel-text="取消"/>
  </page-view>
</template>

<script>
import { mapGetters } from "vuex";
import YhbhEmpty from '@/components/YhbhEmpty/index.vue';
import { hlwZcyh_updateYxyhBdztFromWxgzh, hlwYxyh_updateYxyhMrxzbzFromWxgzh} from '@/api/grzx';
export default {
  data() {
    return {
      list: [],
      selectItem: null,
      actionSheetShow: false,
      actions: [
        {name: '设为默认', value: 'set-default'},
        {name: '解除绑定', value: 'unbind'}
      ]
    };
  },
  computed: {
  ...mapGetters(["openId","phone"]),
  },
  components:{
    YhbhEmpty,
  },
  methods: {
    onClickLeft() {
      this.$refs['pageView'].onClickLeft();
    },
    onClickRight() {
      this.$router.push('/bind-yhbh')
    },
    fetchData() {
      this.$refs['loadingLayout'].setStatus('loading')
       this.$store.dispatch('getYhbhList').then(list => {
        this.list = list;
        if (list.length == 0) {
          this.$refs['loadingLayout'].setStatus('empty')
        } else {
          this.$refs['loadingLayout'].setStatus('success')
        }
      }).catch(err => {
        this.$refs['loadingLayout'].setStatus('error')
      })
    },
    onRefresh() {
      this.$store.dispatch('getYhbhList').then(list => {
        this.list = list;
      });
    },
    onItemClick(item) {
      this.selectItem = item;
      this.actionSheetShow = true;
    },
    onActionSelect(item) {
      if (item.value == 'unbind') {
        this.unBindYhbh();
      } else if (item.value == 'set-default') {
        this.setDefault();
      }
      this.actionSheetShow = false;
    },
    // 设为默认
    setDefault() {
      this.$toast.loading('处理中...')
      hlwYxyh_updateYxyhMrxzbzFromWxgzh(this.selectItem.yhbh, this.openId).then(res => {
        this.$toast.success('已设为默认');
        this.onRefresh();
      }).catch(err => {
        this.$toast.fail('设置默认账号失败')
      });
    },
    // 解除绑定
    unBindYhbh() {
      this.$toast.loading('处理中...')
      hlwZcyh_updateYxyhBdztFromWxgzh(this.selectItem.yhbh, this.openId).then(res => {
        this.$toast.success('已解除绑定');
        this.onRefresh();
      }).catch(err => {
        this.$toast.fail('解除绑定失败')
      });
    }
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style lang="scss" scoped>
  .yhbh-list {
    margin: 10px;
  }
  .van-pull-refresh {
    min-height: 100%;
  }

  .yhbh-item /deep/ {
    margin-bottom: 10px;
    border-radius: 6px;
    background-color: #fff;
    overflow: hidden;
  }
</style>